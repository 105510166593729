import axios, { AxiosError } from 'axios';
import {ProfileData} from "./components/PlayerSearchResult";

const baseUrl = 'https://server.production.durak.ru'

export type ApiResponse<TData> = ApiResponseSuccess<TData> | ApiResponseFail;

export type ApiResponseFail = {
  success: false;
  reason: string;
};

export type ApiResponseSuccess<TData> = {
  success: true;
  data: TData;
};

// Define the classes for each item and offer

export class Resource {
  currency: string;
  amount: number;

  constructor(currency: string, amount: number) {
    this.currency = currency;
    this.amount = amount;
  }
}

export class ShopItem {
  price: number;
  id: string;
  shopId: string;
  isOfferItem: boolean;
  title: string;
  group: string;
  image: string;
  discount: number;
  resources: Resource[];
  labelType: string | null;
  isVisible: boolean;

  constructor(data: any) {
    this.price = data.price;
    this.id = data.id;
    this.shopId = data.shopId;
    this.isOfferItem = data.isOfferItem;
    this.title = data.title;
    this.group = data.group;
    this.image = data.image;
    this.discount = data.discount;
    this.resources = data.resources.map((resource: any) => new Resource(resource.currency, resource.amount));
    this.labelType = data.labelType;
    this.isVisible = data.isVisible;
  }
}

export class OfferItem extends ShopItem {
  offerName: string;
  expirationDate: number;

  constructor(data: any) {
    super(data);
    this.offerName = data.offerName;
    this.expirationDate = data.expirationDate;
  }
}

export class ShopItems {
  chips: ShopItem[];
  tickets: ShopItem[];
  offers: OfferItem[];

  constructor(chips: ShopItem[], tickets: ShopItem[], offers: OfferItem[]) {
    this.chips = chips;
    this.tickets = tickets;
    this.offers = offers;
  }
}

// Define the API function to fetch user profile

export async function fetchUserProfile(playerId: number): Promise<ApiResponse<ProfileData>> {
  try {
    const response = await axios.get(`${baseUrl}/api/v4/portal/profile`, {
      params: { playerId },
    });

    console.log(response.data);
    return {
      success: true,
      data: {
        id: playerId,
        ...response.data,
      } as ProfileData,
    };
  } catch (error) {
    const axiosError = error as AxiosError;

    const reason = axiosError.response?.data?.message || axiosError.message;

    return {
      success: false,
      reason: reason,
    };
  }
}

// Define the API function to fetch shop items

export async function fetchShopItems(playerId: number): Promise<ApiResponse<ShopItems>> {
  try {
    const response = await axios.get(`${baseUrl}/api/v4/portal/yookassa/shop-items`, {
      params: { playerId },
    });

    console.log(response.data);

    const chips: ShopItem[] = [];
    const tickets: ShopItem[] = [];
    const offers: OfferItem[] = [];

    response.data.items.forEach((itemData: any) => {
      const item = new ShopItem(itemData);
      if (item.group === 'Chips') {
        chips.push(item);
      } else if (item.group === 'Tickets') {
        tickets.push(item);
      }
    });

    response.data.offers.forEach((offerData: any) => {
      const offer = new OfferItem(offerData);
      offers.push(offer);
    });

    return {
      success: true,
      data: new ShopItems(chips, tickets, offers),
    };

  } catch (error) {
    const axiosError = error as AxiosError;

    const reason = axiosError.response?.data?.message || axiosError.message;

    return {
      success: false,
      reason: reason,
    };
  }
}

export async function getYookassaToken(email: string, productId: string, playerId: number) {

  let response

  try {
    response = await axios.post(`${baseUrl}/api/v4/portal/yookassa/get-token`, {
      itemId: productId,
      email: email,
      returnUrl: 'https://durakonline3d.ru',
      playerId: playerId
    }, {
      headers: {
        'accept': 'text/plain',
        'Content-Type': 'application/json',
        'Durak-Environment' : "webShop"
      }
    })

  }catch (e){

  }

  if (response) {
    return response.data.token
  } else {
    return ''
  }
}
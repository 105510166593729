import styles from './styles.module.css';
import styleIcon from './icon.module.css';

import ImageGallery from "../../components/ImageGallery";
import type {Image} from "../../components/ImageGallery";
import Collapse, {Cards} from "../../components/Collapse";

import preview1 from './screenshots/screen_497x883_01.png';
import preview2 from './screenshots/screen_497x883_02.png';
import preview3 from './screenshots/screen_497x883_03.png';
import preview4 from './screenshots/screen_497x883_04.png';
import preview5 from './screenshots/screen_497x883_05.png';
import preview6 from './screenshots/screen_497x883_06.png';

import origin1 from './screenshots/screen_745x1325_01.png';
import origin2 from './screenshots/screen_745x1325_02.png';
import origin3 from './screenshots/screen_745x1325_03.png';
import origin4 from './screenshots/screen_745x1325_04.png';
import origin5 from './screenshots/screen_745x1325_05.png';
import origin6 from './screenshots/screen_745x1325_06.png';

const images: Image[] = [
  {
    preview: preview1,
    origin: origin1,
  },
  {
    preview: preview2,
    origin: origin2,
  },
  {
    preview: preview3,
    origin: origin3,
  },
  {
    preview: preview4,
    origin: origin4,
  },
  {
    preview: preview5,
    origin: origin5,
  },
  {
    preview: preview6,
    origin: origin6,
  },
];

const cards: Cards = [
  {
    title: 'Samsung',
    body: 'Настройки ➟ Приложения ➟ нажать меню и выбрать «Специальный доступ» ➟ Установка неизвестных источников',
  },
  {
    title: 'Xiaomi',
    body: 'Настройки ➟ Расширенные настройки ➟ Конфиденциальность ➟ Ненадежные источники',
  },
  {
    title: 'Huawei',
    body: 'Безопасность и конфиденциальность ➟ Дополнительные настройки ➟ Установка приложений из внешних источников',
  },
  {
    title: 'Другие Android',
    body: 'Настройки ➟ Приложения и уведомления ➟ специальный доступ ➟ Установка неизвестных источников. В неизвестных источниках отметьте установку из браузеров (Chrome, Opera, Firefox), файловых менеджеров и проводников',
  },
];

export default function Download() {

  function primaryInstallBlock() {
    return (
      <div className={styles.game_title_container}>
        <div className={styleIcon.icon_game}/>
        <div className={styles.install_container}>
          <h1>Дурак онлайн 3D</h1>
          <div className={styles.approved_title}>
            <div className={styleIcon.icon_valid}/>
            <p>Playvision Games</p>
          </div>
          <a className={styles.install_btn} href={'/durak.apk'}>
            Установить
          </a>
        </div>
      </div>
    );
  }

  function benefitsBlock() {
    return (
      <div className={styles.benefits_container}>
        <div className={styles.benefits}>
          <div className={styles.benefits_text }>
            4.7
            <div className={styleIcon.icon_star}/>
          </div>
          92,796 Отзывов
        </div>
        <div className={styles.benefits}>
          <div className={styles.benefits_text}>1,5М+</div>
          Загрузок
        </div>
        <div className={styles.benefits}>
          <div className={styleIcon.icon_rate}/>
          Разрешено для 12+
        </div>
        <div className={styles.benefits}>
          <div className={styleIcon.icon_child}/>
          Безопасная установка
        </div>
      </div>
    );
  }

  function aboutBlock() {
    return (
      <div className={styles.about_container}>
        <h2>Об игре</h2>
        <div className={styles.standart_text}>
          Дурак Онлайн 3D - современная версия карточной игры.
          Начните играть прямо сейчас и присоединяйтесь к миллионам игроков! Вы еще не встречали таких игр!
        </div>
      </div>
    );
  }

  function galleryBlock() {
    return (
      <div className={styles.layout_gallery}>
        <ImageGallery  images={images}/>
      </div>
    );
  }

  function instructionBlock() {
    return (
      <>
        <div className={styles.about_container}>
          <h2>Инструкция по установке</h2>
          <div className={styles.standart_text}>
            Выполните 3 простых шага и наслаждайтесь игрой “Дурак Онлайн 3D” от Playvision:
          </div>
        </div>

        <div className={styles.instruction_container}>
          <div className={styles.instruction_item}>
            <div className={styles.number}>
              1.
            </div>
            <div className={styleIcon.icon_instruction}>
              <div className={styleIcon.icon_download}/>
            </div>
            <div className={styles.description}>
              Нажмите на кнопку “Установить”, чтобы скачать файл игры
            </div>
          </div>
          <div className={styles.instruction_item}>
            <div className={styles.number}>
              2.
            </div>
            <div className={styleIcon.icon_instruction}>
              <div className={styleIcon.icon_install}/>
            </div>
            <div className={styles.description}>
              Нажмите на скачанный
              файл для установки игры
            </div>
          </div>
          <div className={styles.instruction_item_3}>
            <div className={styles.number}>
              3.
            </div>
            <div className={styleIcon.icon_instruction}>
              <div className={styleIcon.icon_allow}/>
            </div>
            <div className={styles.description}>
              Разрешите установку
              приложений из неизвестных
              источников
            </div>
          </div>
        </div>
      </>
    );
  }

  function unknownSourcesBlock() {
    return (
      <Collapse title={'Как разрешить установку из неизвестных источников'} cards={cards}/>
    );
  }

  function contactsBlock() {
    return (
      <div className={styles.contacts_bot}>
        <div className={styles.standart_text}>
          В случае возникновения любых вопросов обращайтесь к нам через меню поддержки внутри игры или по почте
          <a className={styles.link_text} href="mailto:support@bestin.games">support@bestin.games</a>
        </div>
      </div>
    );
  }

  function secondaryInstallBlock() {
    return (
      <div className={styles.install_container_bot}>
        <a style={{borderRadius: "9px", background: "linear-gradient(180deg, #008033 0%, #006428 100%)"}} className={styles.install_btn} href={'/durak.apk'}>
          Установить
        </a>
        <div className={styles.title_instruction}>
          <div className={styleIcon.icon_child}/>
          <div className={styles.description_aproved}>Проверено. Безопасная установка. Займет несколько минут.</div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.install_game}>
      {primaryInstallBlock()}
      {benefitsBlock()}
      {aboutBlock()}
      {galleryBlock()}
      {instructionBlock()}
      {unknownSourcesBlock()}
      {contactsBlock()}
      {secondaryInstallBlock()}
    </div>
  );
}

// фактически переопределил компонент ../components/ChipsProduct
// TODO если старая реализация ChipsProduct не будет использоваться, то можно спокойно выпиливать

import styles from './shopItemView.module.css';
import React, {useEffect, useMemo, useState} from 'react';
import icon from '../../components/ChipsProduct/icon.png';
import Sticker, {StickerKind} from '../../components/Sticker';
import {ShopItem} from "../../Api";

type Props = {
  product: ShopItem,
  onChooseClick: Function,
  expirationDate?: number,
}

function formatLongNumber(price: number): string {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export default function ShopItemView({product, onChooseClick, expirationDate}: Props) {

  const stickerKind = useMemo<StickerKind | null>(() => {

    // if (product.isBestPrice)
    //   return StickerKind.BEST_PRICE;

    // if (product.isPopular)
    //   return StickerKind.POPULAR;

    // if (product.isSale)
    //   return StickerKind.SALE;

    return null;

  }, [product])

  const chooseButtonClasses = [
    styles.choose_buy,
  ].join(' ');

  function renderSection() {
    if (product.discount > 0) {
      return (
          <>
            <div className={`${styles.price_info} ${styles.sale}`}> Цена</div>
            <div className={`${styles.price} ${styles.sale}`}>{formatLongNumber(product.price)} ₽
              {product.price &&
                  <div className={`${styles.old_price} ${styles.sale}`}>{product.price}</div>}
            </div>
          </>
      )
    }
  }

  function priceDiscount() {
      if (product.discount > 0) {
          return (
              <>
                  {product.price &&
                      <div className={`${styles.old_price} ${styles.sale}`}>{ Math.round((product.price + (product.price * product.discount / 100)))} ₽</div>}
                  <div className={`${styles.price} ${styles.sale}`}>{formatLongNumber(product.price)} ₽
                  </div>
              </>
          )
      }
      else {
          return (<div className={styles.price}>{formatLongNumber(product.price)} ₽</div>)
      }
  }
    function saleTile() {
        if (product.discount > 0) {
            return (
                <>
                    <div className={`${styles.saleTile}`}></div>
                </>
            )
        }
    }

    function CardWithTimer(expirationDate: number) {
        const [timeLeft, setTimeLeft] = useState(expirationDate);

        useEffect(() => {
            const intervalId = setInterval(() => {
                setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
            }, 1000);

            return () => clearInterval(intervalId);
        }, []);

        const formatTime = (timestamp: number) => {
            const currentTime = Math.floor(Date.now() / 1000); // Текущее время в секундах
            const remainingTime =  Math.max(timestamp - currentTime, 0);

            const hours = Math.floor(remainingTime / 3600);
            const minutes = Math.floor((remainingTime % 3600) / 60);
            const seconds = remainingTime % 60;

            return { hours, minutes, seconds };
        };

        const { hours, minutes, seconds } = formatTime(timeLeft);

        return (
                <div className="offer-timer">
                    {timeLeft > 0
                        ? `${String(hours).padStart(2, '0')}ч. ${String(minutes).padStart(2, '0')}м. ${String(seconds).padStart(2, '0')}с.`
                        : ""}
                </div>
        );
    }

    function getShopItem() {
      if (product.isOfferItem) {
          return (
              <>
                  <div className={`${styles.card} ${styles.offer}`}>
                      {CardWithTimer(expirationDate ?? 0)}
                      {stickerKind && <div className={styles.sticker}><Sticker kind={stickerKind}/></div>}
                      <img src={product.image} alt={product.title}/>
                      <div className={styles.value}>
                          {formatLongNumber(product.resources[0].amount)}
                      </div>

                      <button className={`${styles.choose_buy} ${styles.offer}`} onClick={() => onChooseClick()}>
                          {priceDiscount()}
                      </button>
                  </div>
              </>
          );
      }
        return (
            <>
                {saleTile()}
                <div className={`${styles.card}`}>
                    {stickerKind && <div className={styles.sticker}><Sticker kind={stickerKind}/></div>}
                    <img src={product.image} alt={product.title}/>
                    <div className={styles.value}>
                        {formatLongNumber(product.resources[0].amount)}
                    </div>

                    <button className={`${styles.choose_buy}`} onClick={() => onChooseClick()}>
                        {priceDiscount()}
                    </button>
                </div>
            </>
        );
    }

    return (
        <>
            {getShopItem()}
    </>
  );
}
import React, { useEffect, useState } from 'react';
import {ApiResponseSuccess, fetchShopItems, OfferItem, ShopItem} from "../../Api";
import { ShopItems} from "../../Api";

import Spinner from "../../components/Spinner";
import ShopItemView from "../../components/ShopItemView/ShopItemView";



type Props = {
  userId: number,
  setCurrentStep: (step: number) => void,
  onShopItemClick: (shopItem: ShopItem) => void,
};

export default function ChooseProduct({ userId, setCurrentStep, onShopItemClick }: Props) {
  const [products, setProducts] = useState<string>(''); // Состояние для хранения продуктов
  const [loading, setLoading] = useState<boolean>(true); // Состояние для индикатора загрузки
  const [chipsProducts, setChipsProducts] = useState<ShopItem[]>([]);
  const [ticketsProducts, setTicketsProducts] = useState<ShopItem[]>([]);
  const [offerProducts, setOfferProducts] = useState<OfferItem[]>([]);

  function specialOfferTitle() {
    if(offerProducts.length) {
      return <div className="title" style={{gridColumn: '1/-1'}}><h2
        style={{color: '#FFFFFF', fontSize: '24px', margin: '0'}}>Специальное предложение</h2>
      </div>
    }
  }

  function chipsTitle() {
    if(chipsProducts.length) {
      return <div className="title" style={{gridColumn: '1/-1'}}><h2
        style={{color: '#FFFFFF', fontSize: '24px', margin: '0'}}>Фишки</h2>
      </div>
    }
  }

  function ticketsTitle() {
    if(ticketsProducts.length) {
      return <div className="title" style={{gridColumn: '1/-1'}}><h2
        style={{color: '#FFFFFF', fontSize: '24px', margin: '0'}}>Билетики</h2>
      </div>
    }
  }

  useEffect(() => {
    (async () => {
      setLoading(true);

      let response;
      try {
        response = await fetchShopItems(userId);
      } catch (error) {
        console.error('Error fetching shop items:', error);
      } finally {
        setLoading(false); // Устанавливаем loading в false по завершению загрузки
      }

      let shopItems = (response as ApiResponseSuccess<ShopItems>).data;
      setChipsProducts(shopItems.chips);
      setTicketsProducts(shopItems.tickets);
      setOfferProducts(shopItems.offers)
    })();
  }, [userId]); // Добавляем userId как зависимость, чтобы обновлять при его изменении



  return (
    <div>
      {loading && <Spinner />}
      {!loading && <div>{products}</div>}
      <div className="shop-content">
        {specialOfferTitle()}
        {offerProducts.map((product) => (
            <ShopItemView key={product.id}
                          product={product}
                          expirationDate={product.expirationDate}
                          onChooseClick={()=> onShopItemClick(product)}/>
        ))}
        {chipsTitle()}
        {chipsProducts.map((product) => (
            <ShopItemView
              key={product.id}
              product={product}
              onChooseClick={() => onShopItemClick(product)}/>
        ))}
        {ticketsTitle()}
        {ticketsProducts.map((product) => (
            <ShopItemView
              key={product.id}
              product={product}
              onChooseClick={()=> onShopItemClick(product)}/>
        ))}
      </div>
    </div>
  );
}

import styles from './styles.module.css';
import {ChangeEvent, useEffect, useMemo, useState} from 'react';

type Props = {
  highlight: boolean;
  onChange: (value: string) => void,
};

export default function EmailForm({highlight, onChange}: Props) {

  const [value, setValue] = useState<string>(() => localStorage.getItem('playerMail') || '');

  const classes = useMemo(() => {
    return (highlight ? [styles.input, styles.highlight] : [styles.input]).join(' ');
  }, [highlight]);

  useEffect(() => {
    // Вызов onChange с текущим значением email
    onChange(value);
  }, [value, onChange]);

  // Этот эффект будет вызываться один раз при монтировании
  useEffect(() => {
    const savedEmail = localStorage.getItem('playerMail');
    if (savedEmail) {
      setValue(savedEmail);
    }
  }, []);

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setValue(e.target.value);
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.item}>
          <input
            className={classes}
            type="email"
            name="email"
            placeholder="user@example.com"
            value={value}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}

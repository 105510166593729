import { useEffect, useState } from 'react';
import styles from './searchPlayer.module.css';
import PlayerSearchForm from '../../components/PlayerSearchForm';
import { ProfileData } from '../../components/PlayerSearchResult';
import Spinner from '../../components/Spinner';
import { fetchUserProfile } from '../../Api'; // Import your API function
import PlayerSearchError from '../../components/PlayerSearchError';

type Props = {
  onPlayerChange: (profile: ProfileData | null) => void,
  highlight: boolean;
  onHighlightInput: () => void,
  onStepChange: (step: number) => void,
};

export default function SearchPlayer({ onPlayerChange, highlight, onHighlightInput, onStepChange }: Props) {

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [profile, setProfile] = useState<ProfileData | null>(null);

  // при изменении profile выстреливаем
  useEffect(() => onPlayerChange(profile ?? null), [profile, onPlayerChange]);

  async function findPlayer(term: string) {

    setProfile(null);
    setIsLoading(true);

    const isNotOnlyDigits = /\D/.test(term);

    if (isNotOnlyDigits) {
      setMessage('id должен состоять только из цифр, если ваш id содержит буквы, пожалуйста, обновите игру');
      setIsLoading(false);
      return;
    }

    // Use the fetchUserProfile function here instead of axios.get
    const response = await fetchUserProfile(Number(term));

    if (!response.success) {
      setMessage('Игрок не найден');
      console.error(response.reason);
      setIsLoading(false);
      return;
    }

    if (!response.data) {
      setMessage('Игрок не найден');
      setIsLoading(false);
      return;
    }

    setProfile(response.data);
    onPlayerChange(response.data);
    onStepChange(1);
    setIsLoading(false);
  }

  function interceptHighlightInput() {
    setMessage('');
    onHighlightInput();
  }

  function renderForm() {
    return (
      <>
        <PlayerSearchForm
          onSubmit={term => findPlayer(term)}
          disabled={isLoading}
          highlight={!isLoading && highlight}
          onHighlightInput={interceptHighlightInput}
        />
      </>
    );
  }

  function renderResult() {

    if (highlight)
      return <PlayerSearchError message='обязательное поле' />

    if (isLoading)
      return (
        <div className={styles.spinner_container}>
          <Spinner />
        </div>
      );

    if (message)
      return <PlayerSearchError message={message} />;

    return (
      <>
        Чтобы перейти к выбору набора фишек, найдите игрока по id
      </>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>Кому отправить набор фишек?</div>
      <div className={styles.content}>
        <div className={styles.item}>
          {renderForm()}
        </div>
        <div className={styles.item}>
          {renderResult()}
        </div>
{/*        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));*/}
        <div className={styles.explanation}>
          <div className={styles.left}>
            <img src='find-id.png' style={{
             width: '100%', height: 'auto',
            }}/>
          </div>

          <div className={styles.right} style={{
            color: '#FFFFFF',
            fontSize: '18px', fontWeight: '700', padding: '5px', border: '1px solid #1A274C', borderRadius: '10px',
          }}>
            <h3 style={{
              textAlign: "center"
            }}>Как найти игровой ID для совершения покупки</h3>

            <ul style={{
             fontWeight: '400',
            }}>
              <li style={{
                marginTop: '8px',
              }}>
                Убедитесь, что у вас установлена последняя версия приложения.
              </li>
              <li  style={{
                marginTop: '8px',
              }}>
                На экране лобби в верхнем левом углу нажмите на иконку своего профиля.
              </li>
              <li  style={{
                marginTop: '8px',
              }}>
                Найдите ваш игровой ID и нажмите кнопку "Копировать", чтобы скопировать его.
              </li>
              <li  style={{
                marginTop: '8px',
              }}>
                Вставьте скопированный ID в поле выше, чтобы продолжить покупку.
              </li>
            </ul>

          </div>
        </div>
      </div>
    </div>
  );
}

import styles from './styles.module.css'
import {useMemo} from 'react';

import avEmpty from './avatars/empty.png';

export type ProfileData = {
  id: number,
  name: string,
  avatarUrl?: string,
};

function getAvatar(avatar: string | undefined) {

  console.log('avatar:', avatar);
  // no avatar
  if(!avatar)
    return avEmpty;

  // real avatar
  return avatar+"/m.webp";
}

type PlayerSearchResultProps = {
  data: ProfileData;
}

export function PlayerSearchResult({data}: PlayerSearchResultProps) {
  const avatar = useMemo(() => getAvatar(data.avatarUrl), [data]);

  return (
    <div className={styles.container}>
      <img className={styles.avatar} src={avatar} alt={data.name}/>
      <div className={styles.name}>{data.name}</div>
    </div>
  );
}

type PlayerResultProps = {
  data: ProfileData;
  resetPlayer: () => void,
}

export function PlayerSection({data, resetPlayer}: PlayerResultProps) {
  const avatar = useMemo(() => getAvatar(data.avatarUrl), [data]);

  return (
    <div className={styles.user_container}>
      <div className={styles.similar_container}>
        <img className={styles.similar_avatar} src={avatar} alt={data.name}/>
        <div className={styles.similar_name}>{data.name}</div>
        <div className={styles.check_icon}></div>
      </div>
      <div className={styles.edit_user} onClick={resetPlayer}>Изменить</div>
    </div>
  );
}

import React, {useState, useEffect, useMemo} from 'react';

import styles from './finalOrder.module.css';


import EmailForm from '../../components/EmailForm';
import {ShopItem, getYookassaToken} from "../../Api";

type Props = {
  product: ShopItem,
  email: string,
  setEmail: (value: string) => void,
  setCurrentStep: (step: number) => void,
  playerId : number
};

export default function FinalOrder({product, email, setEmail, setCurrentStep, playerId}: Props) {

  const [emailHighlight, setEmailHighlight] = useState(false);
  const [suppressEmailHighlight, setSuppressEmailHighlight] = useState(true);

  // результат валидации email, обновляется вместе с email
  const isValidEmail = useMemo(() => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(email);
  }, [email]);
  
  // при изменении email обновляем подсветку согласно результату валидации
  useEffect(() => {

    // При открытии шага возможны следующие кейсы с полем email:
    // 1. Не заполнено. В этом случае не хотим нервировать пользователя подсветкой.
    // 2. Корректно заполнено автокомплитом. Укладывается в общую логику, подсветки быть не должно.
    // 3. Некорректно заполнено автокомплитом. В этом случае нужно подсветить.

    // Итого: изменение email актуализирует подсветку согласно результату валидации всегда КРОМЕ кейса когда
    // в первый раз (при открытии страницы) значение выставляется в пустую строку.

    email === '' && suppressEmailHighlight
      ? setSuppressEmailHighlight(false)
      : setEmailHighlight(!isValidEmail);

  }, [email]);

  function initPaymentWidget (token: string)  {
        // Проверяем, что объект доступен
        // @ts-ignore
    if (!window.YooMoneyCheckoutWidget) {
      console.error('YooMoneyCheckoutWidget не найден');
    } else {
      // @ts-ignore
      const checkout = new window.YooMoneyCheckoutWidget({
        confirmation_token: token,
        return_url: window.location.origin,
        error_callback: (error: any) => {
          console.error(error);
        }
      });
      checkout.render('payment-form')
        .then(() => {
          // Код, который нужно выполнить после отображения платежной формы.
          console.log('Форма оплаты успешно отображена');
        })
    }
    return (
        <div>
          <h1>Оплата через YooMoney</h1>
          <div id="payment-form"></div>
        </div>
    );
  }


  async function onConfirmClick() {

    // при попытке купить продукт без email активируем подсветку формы
    isValidEmail || setEmailHighlight(true);

    if(!isValidEmail)
      return;

    localStorage.setItem('playerMail', email)

    const token = await getYookassaToken(email, product.id, playerId)

    initPaymentWidget(token);

    setCurrentStep(3);
  }


  function renderProductSection() {
    return(
      <div>
        <img src={product.image}/>
        <div className={styles.price}>{product.resources[0].amount}</div>
      </div>
    )
  }

  return (
    <div className={styles.payform}>
      <div className={styles.priceinfo}>
        <div className={styles.nominal_container}>
          { renderProductSection() }
        </div>
        <div className={styles.price_container}>
          <div className={styles.title}>К оплате</div>
          <div className={styles.price_value}> {product.price} ₽</div>
        </div>
      </div>
      <div className={styles.form}>
        <div className={styles.input_container}>
          <div className={styles.header}>Введите свой email</div>
          <EmailForm onChange={setEmail} highlight={emailHighlight}/>
          <div className={styles.item}>
            <div className={styles.title_info}>
              По закону мы обязаны отправить вам чек. Чек об оплате будет автоматически отправлен на указанную вами почту
            </div>
          </div>
        </div>
        <button className={styles.btn_buy} onClick={() => onConfirmClick()}>Перейти к оплате</button>
      </div>
      <div id='payment-form'></div>
    </div>
  )
}


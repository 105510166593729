import styles from './styles.module.css'
import {ChangeEvent, useState} from 'react';

type PlayerSearchFormProps = {
  onSubmit: (value: string) => void;
  disabled: boolean;
  highlight: boolean;
  onHighlightInput: () => void,
}

export default function PlayerSearchForm({onSubmit, disabled, highlight, onHighlightInput}: PlayerSearchFormProps) {

  const [value, setValue] = useState('');

  const classes = [
    styles.form,
    ...(highlight ? [styles.highlight] : []),
  ].join(' ');

  function onInputChange(e: ChangeEvent<HTMLInputElement>) {

    if(highlight)
      onHighlightInput();

    setValue(e.target.value);
  }

  return (
    <div  style={{display: "flex", gap: "10px"}} className={classes}>
      <input
          style={{borderRadius: "9px"}}
        type="text"
        placeholder="Введите ID игрока"
        value={value}
        onChange={onInputChange}
        onKeyDown={e => e.key === 'Enter' && onSubmit(value)}
        disabled={disabled}
      />

      <button style={{borderRadius: "9px", background: "linear-gradient(180deg, #008033 0%, #006428 100%)"}} onClick={() => onSubmit(value)} disabled={disabled || !value}>Искать</button>
    </div>
  );
}